/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react';

const DEFAULT_INTERVAL = 5000; // 5 secs
const DEFAULT_MAX_ATTEMPTS = 10;

type usePollingProps<T> = {
	interval?: number;
	retryCount?: number;
	onGetData: () => Promise<T>;
	onSuccess?: (data: T) => void;
	onFailure?: (error: Error) => void;
};

// TODO: Introduce exponential backoff one day
export const usePolling = <T>({
	interval = DEFAULT_INTERVAL,
	retryCount = DEFAULT_MAX_ATTEMPTS,
	onGetData,
	onSuccess,
	onFailure,
}: usePollingProps<T>): [
	isPolling: boolean,
	startPolling: () => void,
	stopPolling: () => void
] => {
	const [isPolling, togglePolling] = useState<boolean>(false);
	const isMounted = useRef<boolean>();
	const poll = useRef<NodeJS.Timeout>();

	useEffect(() => {
		if (isMounted) {
			isMounted.current = true;
		}
		return () => {
			stopPolling();
			isMounted.current = false;
		};
	}, []);

	const stopPolling = () => {
		if (isMounted.current) {
			if (poll.current) {
				clearTimeout(poll.current);
				poll.current = undefined;
			}
			togglePolling(false);
		}
	};

	const startPolling = async () => {
		togglePolling(true);
		await fetchData();
	};

	const fetchData = async () => {
		try {
			const response = await onGetData();
			if (response) {
				onSuccess && onSuccess(response);
				stopPolling();
			}
		} catch (e: any) {
			if (retryCount && retryCount > 0) {
				retryCount--;
				await runPolling();
			} else {
				onFailure && onFailure(new Error(e as string));
				stopPolling();
			}
		}
	};

	const runPolling = async () => {
		poll.current = setTimeout(fetchData, interval);
	};

	return [isPolling, startPolling, stopPolling];
};
