import { Divider } from '@mantine/core';

import styled, { css } from 'styled-components';

import { StyledCard } from '../../../../../components/shared/StyledCard';
import { StyledLinkButton } from '../../../../../components/shared/StyledLinkButton';

export const Card = styled(StyledCard)`
	padding: 24px 24px;
	margin-bottom: 24px;
`;
export const Header = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const StyledDivider = styled(Divider)`
	margin: 24px 0;
`;

export const Ellipsis = styled.div<{ $backgroundColor?: string }>(
	({ $backgroundColor = '#ffd75e' }) => `
		border-radius: 50%;
		width: 12px;
		height: 12px;
		background-color: ${$backgroundColor};
		margin-right: 8px;
`
);

export const Indicative = styled.div<{ $backgroundColor?: string }>(
	({ $backgroundColor = '#fff2c9' }) => css`
		display: flex;
		background-color: ${$backgroundColor};
		align-items: center;
		border-radius: 8px;
		padding: 8px 8px;
		width: fit-content;
		margin-bottom: 12px;
	`
);

export const ReportsContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		column-gap: ${spacing.sm};
		row-gap: 16px;
	`
);

export const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	width: fit-content;
	margin-top: 20px;
`;

export const StyledButton = styled(StyledLinkButton)`
	margin-right: 8px;
`;
